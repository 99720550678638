.about {
	margin-top: 100px;
	padding-bottom: 50px;
}

.container {
	width: 85%;
	height: auto;
	margin: auto auto;
	background-color: rgba(0, 0, 0, 0.2);
}

.container .block {
	color: #FFF;
	font-family: Inter;
	font-style: normal;
	letter-spacing: 1.2px;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.container p {
	padding: 30px 40px;
	margin: 0;
}

@media screen and (min-width: 601px) {
	.container {
		width: 60%;
	}
	
	.container .block {
		font-size: 36px;
		line-height: 42px;
	}
}

@media screen and (max-width: 600px) {
	.container .block {
		font-size: 20px;
		line-height: 36px;
	}
}