nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	align-self: center;
	width: 100%;
	min-height: 80px;
	max-height: 80px;
	background: rgba(0,0,0,0.3);
	backdrop-filter: blur(1.5px);
	z-index: 100;
	color: #ffffff;
	position: fixed;
}

nav a {
	padding: 0 30px 0 30px;
	text-decoration: none;
	font-size: 36px;
	font-family: Caveat;
	
}

nav span {
	margin-top: 4px;
	font-size: 20px;
	color: #777777;
	transform: scale(1, 1.1);
}

.disabled {
	opacity: 0.5;
}

@media screen and (min-width: 601px) {

}

@media screen and (max-width: 600px) {
	nav a {
		padding: 0 20px 0 20px;
	}
}