h1 {
	color: #fff0fa;
	font-size: 40px;
	font-family: Inter;
}

.dev {
	display: flex;
	position: fixed;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}