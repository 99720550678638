
.home {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #ffffff;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
}

.home h1 {
	font-family: Irish Grover;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	font-weight: 400;
	margin: 0;
}

.center {
	
}

.description {
	margin-top: 5px;
	text-align: center;
}

.description p {
	font-family: Inter;
	letter-spacing: 1.3px;
	font-weight: 0;
	margin: 0;
}

.spec {
	font-size: 16px;
	color: #609FFF;
	margin-top: 30px;
	text-align: center;
}

.links {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	height: auto;
	display: flex;
	align-items: end;
	justify-content: center;
}

.links a {
	height: 50px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	padding-left: 35px;
	padding-right: 35px;
}

@media screen and (min-width: 601px) {
	.home h1 {
		font-size: 96px;
	}
  
	.description p {
		font-size: 32px;
	}
}

@media screen and (max-width: 600px) {
	.home h1 {
		font-size: 60px;
	}
  
	.description p {
		font-size: 22px;
	}
}